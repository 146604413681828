import React from 'react';
import ProductContainer4 from '../../ProductContainers/ProductContainer4/ProductContainer4';
import SectionHeading3 from '../../SectionHeadings/SectionHeading3/SectionHeading3';
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const NewArrival3 = ({data, flash}) => {

    return (
        <section>
            <SectionWrapper>
                <SectionHeading3 title={'New Horizons'} text={'Must-Have Products for the Modern Era'}/>
                <ProductContainer4 data={data} flash={flash}/>
            </SectionWrapper>
        </section>
    );
};

export default NewArrival3;