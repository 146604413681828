import React from "react";
import {
    SiFacebook,
    SiLinkedin,
    SiPinterest,
    SiTwitter,
    SiYoutube,
} from "react-icons/si";
import {AiFillInstagram} from "react-icons/ai";
import useStore from "../../../Hooks/useStore";
import "./Footer3.css";
import {Link, useNavigate} from "react-router-dom";
import {footerMenu} from "../../../Hooks/Helper";
import {GoLocation} from "react-icons/go";
import {TfiEmail} from "react-icons/tfi";
import {BsTelephone} from "react-icons/bs";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Footer3 = ({staticMenus}) => {
    const {setting} = useStore().data;
    const navigate = useNavigate();
    const {
        facebook,
        instagram,
        linkedin,
        twitter,
        youtube,
        pinterest,
        phone,
        address,
        email,
        name,
    } = setting;

    const newTabWithLink = (link) => {
        window.open(link);
    };
    return (
        <footer className="bg-gray-900">
            <SectionWrapper last={true}>
                <div>
                    <div
                        className="grid grid-cols-2 px-4 lg:px-0 gap-3 lg:grid-cols-5 sm:grid-cols-2  md:grid-cols-3 md:gap-8 border-b">
                        <div>
                            {
                                staticMenus?.length > 0 &&
                                <h5 className="text-lg font-medium text-white dark:text-white">
                                    Important Links
                                </h5>
                            }
                            <ul className="">
                                {staticMenus?.map((item, i) => {
                                    if (item.static_menu_type.name === "Header") {
                                        return null;
                                    }
                                    return (
                                        <li key={i} className="">
                                            <button
                                                className={`border-0`}
                                                onClick={() => {
                                                    navigate(`/page/${item.id}`);
                                                }}
                                            >
                      <span className=" text-white text-sm dark:text-white">
                        {item.menu_name}
                      </span>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {footerMenu?.map((menu, i) => (
                            <div key={i} className="">
                                <div>
                                    <h5 className="text-lg font-medium text-white dark:text-white">
                                        {menu.heading}
                                    </h5>
                                    <ul>
                                        {menu.links.map((item, i) => (
                                            <li key={i}>
                                                <Link
                                                    className="text-sm text-white dark:text-white"
                                                    to={item.route}
                                                >
                                                    {item.linkName}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}

                        <div>
                            <h5 className="text-lg font-medium text-white dark:text-white">
                                Contact us
                            </h5>
                            <div className="mb-1 flex items-center">
              <span>
                {" "}
                  <GoLocation className="text-red-400"/>
              </span>
                                <span className="text-sm text-white dark:text-white ml-2">
                {address}
              </span>
                            </div>
                            <div className="mb-1 flex items-center">
              <span>
                {" "}
                  <TfiEmail className="text-red-400"/>
              </span>
                                <span className="text-sm text-white dark:text-white ml-2">
                {email}
              </span>
                            </div>
                            <div className="flex items-center">
              <span>
                {" "}
                  <BsTelephone className="text-red-400"/>
              </span>
                                <span className="text-sm text-white dark:text-white ml-2">
                {phone}
              </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div
                            className="lg:flex  sm:flex-wrap justify-center items-center gap-2 mb-4 lg:mb-0 cursor-pointer">
            <span className="mx-2 flex items-center text-white text-sm">
              <SiFacebook
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(facebook)}
              />
              Facebook
            </span>
                            <span className="mx-2 flex items-center text-white text-sm">
              <SiTwitter
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(twitter)}
              />
              Twitter
            </span>
                            <span className="mx-2 flex items-center text-white text-sm">
              <AiFillInstagram
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(instagram)}
              />
              Instagram
            </span>
                            <span className="mx-2 flex items-center text-white text-sm">
              <SiLinkedin
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(linkedin)}
              />
              Linkedin
            </span>
                            <span className="mx-2 flex items-center text-white text-sm">
              <SiYoutube
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(youtube)}
              />
              Youtube
            </span>
                            <span className="mx-2 flex items-center text-white text-sm">
              <SiPinterest
                  className="mr-1 text-red-400"
                  onClick={() => newTabWithLink(pinterest)}
              />
              Pinterest
            </span>
                        </div>
                    </div>

                    <div className="flex items-center justify-center mt-5 pb-2">
                        <p className="text-sm text-white">
                            Copyright 2023 © <span className="">{name}</span> All rights reserved. Powered by <span
                            className="text-red-400">Selopia</span>.
                        </p>
                    </div>
                </div>
            </SectionWrapper>

        </footer>
    );
};

export default Footer3;
