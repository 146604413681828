import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { BsPhone, BsEnvelope } from "react-icons/bs";
import "./Footer.css";
import { CiInstagram } from "react-icons/ci";
import { RiVisaLine } from "react-icons/ri";
import { FaStripe, FaCcPaypal } from "react-icons/fa";
import { CiFacebook, CiTwitter, CiYoutube } from "react-icons/ci";
import { TiSocialPinterest } from "react-icons/ti";
import useStore from "../../../Hooks/useStore";
import { baseUrl, footerMenu } from "../../../Hooks/Helper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
const Footer = ({ staticMenus }) => {
  const { setting, darkMode } = useStore().data;
  const navigate = useNavigate();
  const {
    facebook,
    instagram,
    twitter,
    youtube,
    pinterest,
    phone,
    address,
    email,
    name,
    logo,
    dark_logo,
  } = setting;
  const newTabWithLink = (link) => {
    window.open(link);
  };
  return (
    <section className={"footer"}>
      <SectionWrapper last={true}>
        <div>
          <div className={"grid grid-cols-12 gap-3 pt-4"}>
            <div className={"col-span-12 md:col-span-6 xl:col-span-3"}>
              {dark_logo || logo ? (
                  <img
                      className={"w-16 h-16 lg:w-20 object-cover"}
                      src={
                        darkMode
                            ? `${baseUrl.img}${dark_logo}`
                            : `${baseUrl.img}${logo}`
                      }
                      alt={"website logo"}
                  />
              ) : (
                  <img
                      className={"w-16 h-16 lg:w-20 object-cover"}
                      src="https://placehold.co/80x80"
                      alt={"website logo"}
                  />
              )}
              <p className="text-sm text-slate-500 dark:text-white">
                Sign Up for Our Newsletter and Discover Multi-Type Products,
                Exclusive Sales, and Exciting Events!.
              </p>

              <div className="flex  mt-3 cursor-pointer">
                <CiFacebook
                    className=" text-red-400"
                    size={20}
                    onClick={() => newTabWithLink(facebook)}
                />
                <CiInstagram
                    className="ml-2 text-red-400"
                    size={20}
                    onClick={() => newTabWithLink(instagram)}
                />
                <CiTwitter
                    className="ml-2 text-red-400"
                    size={20}
                    onClick={() => newTabWithLink(twitter)}
                />
                <CiYoutube
                    className="ml-2 text-red-400"
                    size={20}
                    onClick={() => newTabWithLink(youtube)}
                />
                <TiSocialPinterest
                    className="mr-2 text-red-400"
                    size={20}
                    onClick={() => newTabWithLink(pinterest)}
                />
              </div>
            </div>
            <div className={"col-span-12 md:col-span-6 xl:col-span-9"}>
              <div className="grid grid-cols-2  lg:grid-cols-3 xl:grid-cols-5 gap-3">
                <div>
                  {staticMenus?.length > 0 && (
                      <h5 className="text-lg font-medium text-slate-500  dark:text-white">
                        Important Links
                      </h5>
                  )}

                  <ul className="">
                    {staticMenus?.map((item, i) => {
                      if (item.static_menu_type.name === "Header") {
                        return null;
                      }
                      return (
                          <li key={i} className="">
                            <button
                                className={`border-0`}
                                onClick={() => {
                                  navigate(`/page/${item.id}`);
                                }}
                            >
                            <span className="text-sm text-slate-500 dark:text-white ">
                              {item.menu_name}
                            </span>
                            </button>
                          </li>
                      );
                    })}
                  </ul>
                </div>
                {footerMenu?.map((menu, i) => (
                    <div key={i} className="">
                      <div>
                        <h5 className="text-lg font-medium whitespace-nowrap text-slate-500  dark:text-white">
                          {menu.heading}
                        </h5>
                        <ul>
                          {menu.links.map((item, i) => (
                              <li key={i}>
                                <Link
                                    className="text-sm text-slate-500  dark:text-white"
                                    to={item.route}
                                >
                                  {item.linkName}
                                </Link>
                              </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                ))}
                <div>
                  <h5 className="text-lg font-medium text-slate-500  dark:text-white">
                    Contact Info
                  </h5>
                  <ul className={"m-0 p-0"}>
                    <li className="">
                      <Link className="text-sm text-slate-500 flex items-center gap-1" to={""}>
                        <span>
                          <BsPhone className="text-red-400"/>
                        </span>
                        <span className="dark:text-white">{phone}</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="text-sm text-slate-500 flex items-center gap-1 mt-1" to={""}>
                        <span>
                          <BsEnvelope className="text-red-400"/>
                        </span>
                        <span className="dark:text-white">{email}</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="text-sm text-slate-500 flex items-center gap-1 mt-1" to={""}>
                        <span>
                          <BiMap className="text-red-400"/>
                        </span>
                        <span className="dark:text-white"> {address}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={"grid grid-cols-1 sm:grid-cols-2 items-center py-3"}>
            <div>
              <p className="text-sm dark:text-white">
                Copyright 2023 © {name} All rights reserved. Powered by{" "}
                <span className="text-red-400">Selopia</span>.
              </p>
            </div>
            <div className={"flex items-center justify-start lg:justify-end"}>
              <ul className={"p-0 m-0 flex items-center"}>
                <li className={"mr-3"}>
                  <RiVisaLine size={25} className="text-red-400"/>
                </li>
                <li className={"mr-3"}>
                  <FaStripe size={25} className="text-red-400"/>
                </li>
                <li className={"mr-3"}>
                  <FaCcPaypal size={25} className="text-red-400"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
};

export default Footer;
