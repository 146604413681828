import React, {useEffect, useRef, useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {baseUrl, errorNotify, successNotify} from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import {MapContainer, TileLayer, Marker, useMapEvents} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {GoArrowLeft, GoArrowRight} from "react-icons/go";
import Button from "../../Button/Button";
import {getPostalCodes} from "../../../Redux/Slices/Location/postalCodeSlice";
import {getAreas} from "../../../Redux/Slices/Location/areaSlice";
import {useDispatch, useSelector} from "react-redux";

const ShippingDetails = () => {
    const [addData, setAddData] = useState({});
    const [showAddModal, setShowAddModal] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [position, setPosition] = useState([]);
    const mapRef = useRef(null);
    const [showMap, setShowMap] = useState(false)
    const dispatch = useDispatch()
    const [districtName, setDistrictName] = useState("")
    const [subDisName, setSubDisName] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [area, setArea] = useState("")
    const postalCodes = useSelector((state) => state.postalCode.postalCodes)
    const areas = useSelector((state) => state.area.area)


    // Get PostalCode & Area
    useEffect(() => {
        if (districtName && subDisName) {
            const data = {
                city: districtName,
                thana: subDisName
            }
            dispatch(getPostalCodes(data))

            if (postalCode) {
                const data = {
                    postcode: postalCode
                }
                dispatch(getAreas(data))
            }
        }

    }, [dispatch, districtName, subDisName, postalCode])

    const HandleMapClick = () => {
        useMapEvents({
            click(e) {
                const {lat, lng} = e?.latlng;
                setPosition([lat, lng]);
            },
        });
        return null;
    };
    const {
        customerToken,
        countries,
        divisions,
        setPageName,
        setDivisionId,
        setCountryId,
        setDistrictId,
        districts,
        subDistricts,
        subDisId,
        update,
        setUpdate,
        setSubDisId,
    } = useStore().data;
    useEffect(() => {
        setPageName("address");
    }, [setPageName]);
    useEffect(() => {
        if (!customerToken) {
            return;
        }


        const getUserDetails = async () => {
            const res = await fetch(`${baseUrl.url}/user/address-list`, {
                headers: {
                    Authorization: `bearer ${customerToken}`,
                },
            });
            const userData = await res.json();
            if (res.ok) {
                setAddresses(userData.data);
            } else {
                setAddresses([]);
            }
        };
        getUserDetails().then();
    }, [customerToken, update, setUpdate]);
    const removeAddress = async (id) => {
        const res = await fetch(`${baseUrl.url}/user/address-delete/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `bearer ${customerToken}`,
            },
        });
        const resData = await res.json();
        if (res.ok) {
            successNotify("successfully remove your address");
            setUpdate(!update);
        } else {
            resData?.errors.map((err) => errorNotify(err));
        }
    };
    const handleInputChange2 = (event) => {
        const {name, value} = event.target;
        setAddData((prevData) => ({...prevData, [name]: value}));
    };
    //ADD ADDRESS
    const innerWidth = window?.innerWidth;
    //ADD ADDRESS
    const addAddress = async (e) => {
        e.preventDefault();
        const postData = {
            upazila_id: subDisId,
            postal_code: postalCode,
            area: area,
            phone_no: addData?.phone_no,
            address: addData?.address,
        }

        if (!postData?.upazila_id) {
            errorNotify("Please select your upazila")
            return
        }
        if (!postData?.postal_code) {
            errorNotify("Postal code is required")
            return
        }
        if (!postData?.address) {
            errorNotify("Address is required")
            return
        }
        if (position.length === 0) {
            errorNotify("Please select your location from the map")
            return
        }

        postData.is_default = 0;
        const formData = new FormData();
        for (const key in postData) {
            const element = postData[key];
            formData.append(`${key}`, element);
        }
        formData.append("lat", position[0])
        formData.append("lng", position[1])

        const res = await fetch(`${baseUrl.url}/user/create-new-address`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${customerToken}`,
            },
            body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
            setUpdate(!update)
            setShowAddModal(false);
            successNotify("successfully address created");
        } else {
            errorNotify(resData?.errors[0])
        }
    };
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Rodal
                visible={showAddModal}
                enterAnimation="flip"
                animation="slideRight"
                onClose={() => setShowAddModal(!showAddModal)}
                customStyles={{
                    height: "auto",
                    width: innerWidth > 1024 ? "40%" : "90%",
                    bottom: "auto",
                    top: "10%",
                    transform: "translateY(-50%)",
                }}
            >
                <p className="text-xl border-b py-3 text-black">Create New Address</p>

                <form onSubmit={addAddress} className=" py-3">
                    <div className={`grid grid-cols-12 gap-3 ${showMap && "hidden"}`}>
                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Country
                            </label>
                            <select
                                onChange={handleInputChange2}
                                className="w-full border-b bg-transparent border-gray-500 focus:outline-none"
                                onClick={(e) => setCountryId(e.target.value)}
                                name="country_id"
                                id=""
                            >
                                <option className="" value="">
                                    --Please select country--
                                </option>
                                {countries?.map((country, i) => (
                                    <option key={i} value={country?.id}>
                                        {country?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-12 lg:col-span-6 w-full">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Division
                            </label>
                            <select
                                name="division_id"
                                onClick={(e) => {
                                    setDivisionId(e.target.value);
                                    setDistrictId("");
                                    setSubDisId("");
                                    setSubDisName("")
                                    setDistrictName("")
                                    setPostalCode("")
                                    setArea("")
                                }}
                                onChange={handleInputChange2}
                                className="text-black form-control border-0 border-b border-gray-500 rounded-0  bg-transparent w-full focus:outline-none"
                                id=""
                            >
                                <option value="">--Select country first--</option>
                                {divisions?.length > 0 &&
                                    divisions?.map((div, i) => (
                                        <option key={i} value={div?.id}>
                                            {div?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                District
                            </label>
                            <select
                                name="district_id"
                                onClick={(e) => {
                                    setDistrictName(e.target.selectedOptions[0].text)
                                    setDistrictId(e.target.value);
                                    setSubDisId("");
                                    setSubDisName("")
                                    setPostalCode("")
                                    setArea("")
                                }}
                                onChange={handleInputChange2}
                                className="w-full border-gray-500 text-black form-control border-0 border-b rounded-0  bg-transparent  focus:outline-none"
                                id=""
                            >
                                <option value="">--Select division first--</option>
                                {districts?.length > 0 &&
                                    districts?.map((dis, i) => (
                                        <option key={i} value={dis?.id}>
                                            {dis?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Upazila
                            </label>
                            <select
                                name="upazila_id"
                                onClick={(e) => {
                                    setSubDisId(e.target.value)
                                    setSubDisName(e.target.selectedOptions?.length > 0 && e.target.selectedOptions[0].text)
                                    setPostalCode("")
                                    setArea("")
                                }}
                                onChange={handleInputChange2}
                                className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                id=""
                            >
                                <option value="">--Select district first--</option>
                                {subDistricts?.length > 0 &&
                                    subDistricts?.map((subD, i) => (
                                        <option key={i} value={subD?.id}>
                                            {subD?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Postal/Zip code
                            </label>
                            <select
                                name="postal_code"
                                onClick={(e) => {
                                    setPostalCode(e.target.value)
                                    setArea("")
                                }}
                                onChange={handleInputChange2}
                                className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                id=""
                            >
                                <option value="">--Select Postal Code--</option>
                                {postalCodes?.length > 0 &&
                                    postalCodes?.map((code, i) => (
                                        <option key={i} value={code?.id}>
                                            {code?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Area
                            </label>
                            <select
                                name="area"
                                onClick={(e) => {
                                    setArea(e.target.value)
                                }}
                                onChange={handleInputChange2}
                                className="w-full border-gray-500 border-0 border-b rounded-0  bg-transparent text-black  focus:outline-none "
                                id=""
                            >
                                <option value="">--Select Area--</option>
                                {areas?.length > 0 &&
                                    areas?.map((area, i) => (
                                        <option key={i} value={area?.id}>
                                            {area?.name}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div className="col-span-12 lg:col-span-6">
                            <label className="font-semibold text-gray-800 block" htmlFor="">
                                Phone number
                            </label>
                            <input
                                name="phone_no"
                                onChange={handleInputChange2}
                                type="number"
                                className="w-full border-0 border-b border-gray-500 rounded-0  bg-transparent text-black focus:outline-none"
                            />
                        </div>
                        <div className="col-span-12">
                            <label className="font-semibold text-gray-800" htmlFor="">
                                Address
                            </label>
                            <textarea
                                name="address"
                                type="text"
                                onChange={handleInputChange2}
                                className="w-full text-black border-gray-500 form-control border p-5 rounded  bg-transparent focus:outline-none"
                            />
                        </div>
                        <div className="flex justify-end col-span-12">
                            <span className="flex items-center gap-1 text-red-400 cursor-pointer"
                                  onClick={() => setShowMap(true)}>Next <GoArrowRight size="21"/></span>
                        </div>
                    </div>


                    {
                        showMap && <div className="col-span-12 block">
                            <div className="pb-1">
                                <span className="flex items-center gap-1 text-red-400 cursor-pointer"
                                      onClick={() => setShowMap(false)}><GoArrowLeft size="21"/>Previous</span>
                            </div>
                            <MapContainer
                                center={[23.76, 90.38]}
                                zoom={13}
                                style={{height: "400px", width: "100%"}}
                                ref={mapRef}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {position?.length > 0 && (
                                    <Marker
                                        position={position}
                                        icon={L.icon({
                                            iconUrl: `https://www.iconpacks.net/icons/2/free-location-icon-2955-thumb.png`,
                                            iconSize: [32, 32],
                                            iconAnchor: [16, 32],
                                        })}
                                    >
                                        {/* <Popup>Selected Location</Popup> */}
                                    </Marker>
                                )}
                                <HandleMapClick/>
                            </MapContainer>
                            <div className="mt-4">
                                <Button submit={true} size={"md"} title={"Add address"}/>
                            </div>
                        </div>
                    }
                </form>
            </Rodal>

            <div className="flex align-items-start justify-between">
                <div>
                    <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
                        Shipping address
                    </h2>
                </div>
                <div>
                    <Button size={"md"} func={() => setShowAddModal(true)} title={"Add Address"}/>
                </div>
            </div>

            <div className="pb-4 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-2 mt-8">
                {addresses
                    ?.slice(0, addresses?.length)
                    .reverse()
                    .map((add, i) => (
                        <div
                            key={i}
                            className="bg-slate-100 dark:bg-slate-900 p-4 relative group"
                        >
                            <div className="flex items-center justify-between">
                                <h1 className="text-2xl text-gray-400 ">
                                    {i + 1}
                                </h1>
                                <button onClick={() => removeAddress(add?.id)}
                                        className="text-gray-500 group-hover:text-red-500 text-sm">Remove
                                </button>
                            </div>
                            <div className={`rounded cursor-pointer `}>
                                <p className="dark:text-white mt-4 text-sm">
                                    {add?.upazila?.district?.division?.country?.name},
                                    {add?.upazila?.district?.division?.name},
                                    {add?.upazila?.district?.name}
                                </p>
                                <p className="dark:text-white text-sm">{add?.upazila?.name},{add?.address}</p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ShippingDetails;
