import React, { useEffect, useState } from "react";
import { RxDoubleArrowUp } from "react-icons/rx";
import "react-toastify/dist/ReactToastify.css";
import "./Customization.css";
import { scrollToTop } from "../../Hooks/Helper";
import {
  BsMoonStarsFill,
  BsSun,
} from "react-icons/bs";
import useStore from "../../Hooks/useStore";
// import ChatBox from "../ChatBox/ChatBox";
const Customization = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => window.addEventListener("scroll", toggleVisible), []);
  const {
    darkMode,
    setDarkMode

  } = useStore().data;

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };



  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode) {
      setDarkMode(savedDarkMode === "true");
    } else {
      setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    }
  }, [setDarkMode]);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <div>
      <div className="config-container">
        {visible && (
          <div
            className="p-2 rounded-full text-black cursor-pointer"
            onClick={scrollToTop}
          >
            <RxDoubleArrowUp className="slide-up text-center text-black dark:text-white" />
          </div>
        )}
        <button onClick={toggleDarkMode} className="mt-4">
          {darkMode ? (
            <BsSun className="text-white" />
          ) : (
            <BsMoonStarsFill className="text-black" />
          )}
        </button>
        {/* <ChatBox/> */}
      </div>
    </div>
  );
};

export default Customization;
