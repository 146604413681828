import React, {useEffect} from 'react';
import ProductDetailsBanner from '../../Components/ProductDetailsBanner/ProductDetailsBanner';
import Brands from '../../Components/Brands/Brands'
import {useParams} from 'react-router-dom';
import SimillarProducts from '../../Components/SimillarProducts/SimillarProducts';

const ProductDetails = () => {
    const {id} = useParams()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [id])

    // useEffect(() => {
    //     const getProductDetails = async () => {
    //         setLoader(true);
    //         try {
    //             const res = await fetch(`${baseUrl.url}/product-details/${id}`, {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: baseUrl.token,
    //                 },
    //             });
    //             const resData = await res.json();
    //             if (res.ok && res.status !== 204) {
    //                 setProductDetails(resData?.data);
    //                 SetFlashSale(resData?.flash_sale);
    //                 setSelectedIds(resData?.data.default_combination[0]?.attribute_values);
    //             }
    //         } catch (e) {
    //             console.log(e)
    //         } finally {
    //             setLoader(false);
    //         }
    //     };
    //     getProductDetails().then();
    // }, [id, setProductDetails, SetFlashSale, setSelectedIds]);
    return (
        <>
            {/*<ProductDetailsBanner loader={loader} setLoader={setLoader}/>*/}
            <ProductDetailsBanner/>
            <SimillarProducts/>
            <Brands/>
        </>
    );
};

export default ProductDetails;