import React, {useEffect, useState} from "react";
import {
    BsArrowRight,
    BsCart3,
    BsGrid,
    BsHeart,
    BsSearch,
} from "react-icons/bs";
import {HiBars3, HiOutlineUserCircle} from "react-icons/hi2";

import useStore from "../../../Hooks/useStore";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./NavBar3.css";
import {fetchCartList} from "../../../Redux/Slices/Cart/cartListSlice";
import {TfiAngleDown} from "react-icons/tfi";
import {BiGitCompare} from "react-icons/bi";
import {fetchAllwishList} from "../../../Redux/Slices/Wishlist/wishlistSlice";
import {baseUrl, handleLogout} from "../../../Hooks/Helper";
import {fetchAllcategories} from "../../../Redux/Slices/category/categoriesSlice";
import Button from "../../Button/Button";
// import Button from "../../Button/Button";
const NavBar3 = ({staticMenus, flash}) => {
    const navigate = useNavigate();
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const {
        setting,
        customerToken,
        secondaryToken,
        setProductName,
        productName,
        setCustomerToken,
        setCategoryId,
        update,
        compare,
        customerInfo,
        darkMode,
    } = useStore().data;
    const {phone, email, logo, dark_logo} = setting;

    //cart items
    const dispatch = useDispatch();
    const {cartItems, updates} = useSelector((state) => state?.cartItems);
    useEffect(() => {
        dispatch(fetchCartList({customerToken, secondaryToken}));
    }, [dispatch, updates, customerToken, secondaryToken, update]);
    //all categories
    const allCategories = useSelector((state) => state?.categories?.categories);
    useEffect(() => {
        dispatch(fetchAllcategories());
    }, [dispatch]);
    //wishlist
    const {wishlist} = useSelector((state) => state?.wishlist);
    const verified = customerInfo?.user_data?.email_verified_at;
    useEffect(() => {
        if (!customerToken || !verified) {
            return;
        }
        if (customerToken) {
            dispatch(fetchAllwishList({customerToken}));
        }
    }, [dispatch, customerToken, update, verified]);

    // const logOut = async () => {
    //   const res = await fetch(`${baseUrl.url}/user/logout`, {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       Authorization: `bearer ${customerToken}`,
    //     },
    //   });
    //   if (res.ok) {
    //     setCustomerToken("");
    //     setUpdate(!update);
    //     // setAddresses([]);
    //   }
    //   removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
    //   navigate("/");
    // };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            navigate("/products", {
                state: {
                    productName: productName,
                    selectedCategory: selectedCategory,
                },
            });
            setProductName("");
            setSelectedCategory("");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300) {
                setSticky(true);
            } else if (scrolled <= 200) {
                setSticky(false);
            }
        });
    }, []);
    return (
        <div className="grid grid-cols-12 lg:grid-cols-1 items-center border-b md:border-none relative">
            <div className="py-3 col-span-9">
                <div className="container">
                    <div className="grid grid-cols-12  items-center justify-between">
                        <div className="col-span-8 lg:col-span-3">
                            <div onClick={() => {
                                navigate('/')
                            }} className="h-[30px]">
                                {dark_logo || logo ? (
                                    <img
                                        className={"w-auto h-full"}
                                        src={
                                            darkMode
                                                ? `${baseUrl.img}${dark_logo}`
                                                : `${baseUrl.img}${logo}`
                                        }
                                        alt={"website logo"}
                                    />
                                ) : (
                                    <img
                                        className={"h-10 object-cover"}
                                        src="https://placehold.co/192x40"
                                        alt={"website logo"}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="hidden lg:block col-span-1 lg:col-span-6">
                            <div className="bg-gray-100 p-2 grid grid-cols-12 items-center gap-2">
                                <select
                                    className="col-span-5 xl:col-span-4 bg-transparent focus:outline-none"
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        setCategoryId(e.target.value);
                                        setSelectedCategory(e.target.value);
                                    }}
                                >
                                    <option value="">Select a Category</option>
                                    {allCategories?.data?.map((category, index) => (
                                        <option key={index} value={category?.id}>
                                            {category?.name}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    className="focus:outline-none w-full bg-transparent col-span-6 xl:col-span-7 border-l pl-4"
                                    placeholder="Search here"
                                    id="productName"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                                <div className="col-span-1 cursor-pointer">
                                    <BsSearch/>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:block col-span-5 lg:col-span-3 lg:ml-5">
                            <div className="grid grid-cols-2 md:gap-[6rem] lg:gap-[5.75rem] xl:gap-2">
                                <div>
                                    <h3 className="dark:text-gray-200 whitespace-nowrap text-sm 2xl:text-base">
                                        Send mail
                                    </h3>
                                    <p className="text-xs dark:text-white">{email}</p>
                                </div>

                                <div>
                                    <h3 className="dark:text-gray-200 whitespace-nowrap text-sm 2xl:text-base">
                                        24/7h support
                                    </h3>
                                    <p className="text-xs dark:text-white">{phone}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`bg:transparent lg:bg-gray-900 col-span-3 ${
                    sticky ? "sticky-nav backdrop-blur-md bg-white/30" : ""
                }`}
            >
                <div className="container grid grid-cols-12">
                    <div className="col-span-12 md:col-span-2 relative group">
                        <div className="bg-transparent lg:bg-gray-800 p-4 flex items-center justify-between text-white">
              <span className="flex items-center text-white">
                <HiBars3
                    onClick={() => setShowSideMenu(!showSideMenu)}
                    size={25}
                    className="block lg:hidden mr-2 text-black lg:text-white dark:text-white cursor-pointer"
                />
                <span className="hidden lg:block whitespace-nowrap text-sm xl:text-base">
                  All Categories
                </span>
              </span>
                            <TfiAngleDown className="hidden lg:block"/>
                        </div>
                        <div
                            className="hidden lg:block absolute w-full h-fit bg-white z-50 opacity-0 invisible group-hover:visible group-hover:opacity-100 p-3 dark:bg-gray-800">
                            <ul>
                                {allCategories?.data?.map((cat, i) => {
                                    const {name} = cat;
                                    return (
                                        <li key={i} className="flex items-center p-2 dark:text-white">
                                            <BsArrowRight className="mr-2"/>
                                            <button
                                                className="font-bold whitespace-nowrap text-sm"
                                                onClick={() => {
                                                    setCategoryId(cat?.id);
                                                    navigate("/products");
                                                }}
                                            >
                                                {name}
                                            </button>
                                        </li>
                                    );
                                })}
                                {allCategories?.data?.length > 12 && (
                                    <li className="flex items-center p-2">
                                        <BsGrid className="mr-2"/>
                                        <button
                                            className="font-bold whitespace-nowrap text-center"
                                            onClick={() => navigate("/products")}
                                        >
                                            {"View All"}
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div
                        className={`transition-all duration-300 absolute top-full lg:top-0 w-full lg:w-fit ${
                            showSideMenu ? "left-0" : "-left-full"
                        } lg:left-0 bg-black lg:bg-transparent lg:relative col-span-5 xl:col-span-6 z-50 lg:z-10 overflow-x-scroll lg:overflow-hidden`}
                    >
                        <ul className="p-0 m-0 flex items-center nav-menu-items">
                            <li className="mx-3">
                                <Link
                                    className="py-4 text-white block"
                                    to="/"
                                    onClick={() => {
                                        setShowSideMenu(false);
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="mx-3">
                                <Link
                                    className="p-4 text-white"
                                    to="/products"
                                    onClick={() => {
                                        setShowSideMenu(false);
                                    }}
                                >
                                    Products
                                </Link>
                            </li>
                            {staticMenus?.map((item, i) => {
                                if (item.static_menu_type.name === "Footer") {
                                    return null;
                                }
                                return (
                                    <li key={i} className="mr-5">
                                        <button
                                            className={`border-0 d-flex align-items-center`}
                                            onClick={() => {
                                                navigate(`/page/${item.id}`);
                                                setShowSideMenu(false);
                                            }}
                                        >
                      <span className="ml-2 text-white whitespace-nowrap">
                        {item.menu_name}
                      </span>
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="hidden lg:block col-span-5 xl:col-span-4">
                        <div className="flex items-center justify-end">
                            <ul className="flex items-center mr-2">
                                {customerToken && customerToken.length > 20 ? (
                                    <li>
                                        <button className="text-white"
                                                onClick={() => handleLogout(customerToken, setCustomerToken, navigate())}>
                                            Logout
                                        </button>
                                    </li>
                                ) : (
                                    <ul className="flex items-center">
                                        <li>
                                            <Link className="text-white" to={"/login"}>
                                                Login/
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="text-white" to={"/register"}>
                                                Register
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </ul>
                            <ul className="p-0 m-0 flex items-center nav-icons-group">
                                <li className="ml-4 rounded-full flex items-center justify-center">
                                    {customerToken && customerToken.length > 20 ? (
                                        <Link className="relative py-4 block" to={"/wishlist"}>
                                            <BsHeart className="text-white" size={18}/>
                                            {wishlist?.length > 0 && (
                                                <div
                                                    className="absolute bottom-7 text-xs left-2 rounded-full flex items-center justify-center bg-white text-gray-700 h-4 w-4  font-bold">
                                                    {wishlist?.length}
                                                </div>
                                            )}
                                        </Link>
                                    ) : (
                                        ""
                                    )}
                                </li>
                                <li className="ml-4 rounded-full flex items-center justify-center">
                                    <Link className="relative py-4 block" to={"/compare"}>
                                        <BiGitCompare className="text-white" size={18}/>
                                        {compare?.length > 0 && (
                                            <div
                                                className="absolute bottom-7 text-xs left-2 rounded-full flex items-center justify-center bg-white text-gray-700 h-4 w-4  font-bold">
                                                {compare?.length}
                                            </div>
                                        )}
                                    </Link>
                                </li>
                                <li className="ml-4  flex items-center justify-center">
                                    <div className="relative">
                                        <div className="rounded-full cart flex items-center justify-end">
                                            <Link className="relative py-4 block" to={"/cart"}>
                                                <BsCart3 className="text-white" size={18}/>
                                                {cartItems?.length > 0 && (
                                                    <div
                                                        className="absolute font-bold bottom-7 text-xs left-2 rounded-full bg-white text-gray-700 h-4 w-4 flex items-center justify-center">
                                                        {cartItems?.length}
                                                    </div>
                                                )}
                                            </Link>
                                            <div className="cart-hover-item bg-white dark:bg-slate-800">
                                                {cartItems?.length > 0 ? (
                                                    <div>
                                                        {cartItems?.slice(0, 5)?.map((cart, i) => (
                                                            <div key={i} className="grid grid-cols-12 mb-2">
                                                                <div className="col-span-3">
                                                                    <img
                                                                        className="dark:bg-slate-900 rounded-lg"
                                                                        style={{height: "50px", width: "50px"}}
                                                                        src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                                                        alt="cart"
                                                                    />
                                                                </div>
                                                                <div className="col-span-9">
                                                                    <p
                                                                        style={{lineHeight: "15px"}}
                                                                        className="p-0 m-0 fw-bold font-medium dark:text-white"
                                                                    >
                                                                        {cart?.product_combination?.product?.name}
                                                                    </p>
                                                                    <p className="dark:text-white">
                                                                        {cart?.product_quantity} X ৳
                                                                        {flash &&
                                                                        cart?.product_combination?.product
                                                                            ?.is_on_sale === 1 ? (
                                                                            <span>
                                         {(cart?.product_combination
                                                 ?.selling_price -
                                             (cart?.product_combination
                                                 ?.selling_price * flash.discount) /
                                             100).toFixed(2)}
                                      </span>
                                                                        ) : (
                                                                            <span>
                                        {
                                            cart?.product_combination
                                                ?.selling_price
                                        }
                                      </span>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="p-3 text-center">
                                                        <div style={{width: "60px"}} className="mx-auto">
                                                            <img
                                                                src={require("../../../assets/cart/no-item.png")}
                                                                alt=""
                                                                className="w-full"
                                                            />
                                                        </div>
                                                        <p className="dark:text-white">No item in cart</p>
                                                    </div>
                                                )}
                                                <div>
                                                    <Link className="block" to={"/cart"}>
                                                        <Button
                                                            classes={"w-full"}
                                                            size={"md"}
                                                            title={"View Cart"}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li className="ml-4  flex items-center justify-center ">
                                    {customerToken ? (
                                        <Link
                                            to={"/profile"}
                                            className=" flex items-center justify-center"
                                        >
                                            <img
                                                style={{height: "25px", width: "25px"}}
                                                className="rounded-full overflow-hidden"
                                                src={`${baseUrl.img}${customerInfo?.user_data?.profile?.image}`}
                                                alt=""
                                            />
                                        </Link>
                                    ) : (
                                        <Link className=" block py-4" to={"/profile"}>
                                            <HiOutlineUserCircle className="text-white" size={25}/>
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className={`${
                            sticky
                                ? "absolute top-0 right-4  mt-1 lg:hidden  block"
                                : "absolute top-0 md:right-[12rem] sm:right-[12rem] right-[4.5rem] xl:right-14 mt-1 lg:hidden  block"
                        }`}
                    >
                        <ul className="p-0 m-0 flex items-center nav-icons-group">
                            <li className="ml-4 rounded-full flex items-center justify-center">
                                {customerToken && customerToken.length > 20 ? (
                                    <Link className="relative py-4 block" to={"/wishlist"}>
                                        <BsHeart className="" size={18}/>
                                        {wishlist?.length > 0 && (
                                            <div
                                                className=" absolute bottom-8 text-xs right-0 rounded-full flex items-center justify-center">
                                                {wishlist?.length}
                                            </div>
                                        )}
                                    </Link>
                                ) : (
                                    ""
                                )}
                            </li>
                            <li className="ml-4 rounded-full flex items-center justify-center">
                                <Link className="relative py-4 block" to={"/compare"}>
                                    <BiGitCompare className="" size={18}/>

                                    {compare?.length > 0 && (
                                        <div
                                            className=" absolute bottom-8 text-xs right-0 rounded-full flex items-center justify-center">
                                            {compare?.length}
                                        </div>
                                    )}
                                </Link>
                            </li>
                            <li className="ml-4 rounded-full flex items-center justify-center">
                                <Link className="relative py-4 block" to={"/cart"}>
                                    <BsCart3 className="" size={18}/>
                                    {cartItems?.length > 0 && (
                                        <div
                                            className=" absolute bottom-8 text-xs right-0 rounded-full flex items-center justify-center">
                                            {cartItems?.length}
                                        </div>
                                    )}
                                </Link>
                            </li>
                            <li className="ml-4  flex items-center justify-center ">
                                {customerToken ? (
                                    <Link
                                        to={"/profile"}
                                        className=" flex items-center justify-center"
                                    >
                                        <img
                                            style={{height: "20px", width: "20px"}}
                                            className="rounded-full overflow-hidden"
                                            src={`${baseUrl.img}${customerInfo?.user_data?.profile?.image}`}
                                            alt=""
                                        />
                                    </Link>
                                ) : (
                                    <Link className=" block py-4" to={"/profile"}>
                                        <HiOutlineUserCircle size={25}/>
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar3;
