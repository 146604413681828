import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { TfiSearch } from "react-icons/tfi";
import { MdSettingsVoice } from "react-icons/md";
import { IoMdHeartEmpty } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import useStore from "../../../Hooks/useStore";
import {baseUrl, handleLogout, navItems} from "../../../Hooks/Helper";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { VscGitCompare } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartList } from "../../../Redux/Slices/Cart/cartListSlice";
import { fetchAllwishList } from "../../../Redux/Slices/Wishlist/wishlistSlice";
import { FiPhoneCall } from "react-icons/fi";
import Button from "../../Button/Button";
const Navbar = ({ staticMenus, flash }) => {
  //necessary function calls from packages
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const navigate = useNavigate();
  //necessary states from context api
  const {
    compare,
    customerToken,
    setViewAllFlashSell,
    setDiscount,
    setFeatureCat,
    setSubCategoryId,
    setCategoryId,
    setMaxPrice,
    setMinPrice,
    refreshAll,
    setRefreshAll,
    setProductName,
    update,
    setCustomerToken,
    customerInfo,
    darkMode,
  } = useStore().data;
  //necessary useStates
  const [showSideBar, setShowSideBar] = useState(false);
  const [searchbar, setSearchbar] = useState(false);
  const location = useLocation().pathname;
  const [searchTerm, setSearchTerm] = useState("");
  const [sticky, setSticky] = useState(false);
  const dispatch = useDispatch();
  const { setting } = useStore().data;
  const { phone, logo, dark_logo } = setting;
  const { cartItems } = useSelector((state) => state?.cartItems);
  const { wishlist } = useSelector((state) => state?.wishlist);
  const verified = customerInfo?.user_data?.email_verified_at;
  //useEffect for cartItems
  useEffect(() => {
    dispatch(fetchCartList({ customerToken }));
  }, [dispatch, customerToken, update]);

  //useEffect for wishList Items
  useEffect(() => {
    if (!customerToken || !verified) {
      return;
    }
    if (customerToken) {
      dispatch(fetchAllwishList({ customerToken }));
    }
  }, [dispatch, customerToken, update, verified]);
  //handle search functionality
  const handleSearchChange = (event) => {
    if (event.key === "Enter") {
      setProductName(searchTerm);
      navigate("/products");
      event.target.reset();
    } else {
      setProductName(searchTerm);
      navigate("/products");
    }
  };
  useEffect(() => {
    if (transcript.length > 0) {
      setProductName(transcript);
      navigate("/products");
    }
  }, [transcript, navigate, setProductName]);


  //logout functions
  // const logOut = async () => {
  //   const res = await fetch(`${baseUrl.url}/user/logout`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       Authorization: `bearer ${customerToken}`,
  //     },
  //   });
  //   if (res.ok) {
  //     setCustomerToken("");
  //     setUpdate(!update);
  //   }
  //   removeToken(["isLogin", "customer_access_token", "remember_me_token"]);
  //   navigate("/");
  // };

  //sticky navbar
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setSticky(true);
      } else if (scrolled <= 200) {
        setSticky(false);
      }
    });
  }, []);

  return (
    <div
      className={`navBar dark:bg-gray-800 ${
        sticky ? "sticky-nav backdrop-blur-md bg-white/30" : ""
      }`}
    >
      <div
        className={`searchbar-layout ${searchbar ? "showlayout" : ""}`}
        onClick={() => {
          setSearchbar(false);
          resetTranscript();
        }}
      ></div>
      <div
        className={`searchbar-container w-full flex items-center justify-center ${
          searchbar ? "showsearchbar  dark:bg-slate-800" : ""
        }`}
      >
        <div className="container">
          <div className=" mb-3 mx-auto">
            <div className="flex justify-between items-center">
              <div className="">
                <div
                  onKeyDown={handleSearchChange}
                  className="flex items-center gap-2"
                >
                  <TfiSearch
                    size={20}
                    className="dark:text-white hidden lg:block"
                    onClick={handleSearchChange}
                  />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm || transcript}
                    type="text"
                    placeholder="Search Product"
                    className="bg-transparent dark:text-white focus:outline-none border-d"
                  />
                </div>
              </div>

              <div className="flex">
                {(browserSupportsSpeechRecognition && transcript.length > 0) ||
                searchTerm ? (
                  <button
                    className="btn"
                    onClick={() => {
                      resetTranscript();
                      setProductName("");
                      setSearchTerm("");
                    }}
                  >
                    <RxCross1 className="dark:text-red-400" />
                  </button>
                ) : (
                  <button
                    className={`bg-grey2 w-8 h-8 flex items-center justify-center rounded-full ${
                      listening
                        ? "blink-color"
                        : "border dark:border-slate-600 dark:text-white"
                    }`}
                    onClick={SpeechRecognition.startListening}
                  >
                    <MdSettingsVoice />
                  </button>
                )}
              </div>
              <RxCross1
                onClick={() => setSearchbar(false)}
                size={20}
                className="text-secondary dark:text-red-400 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
      {/* searchbar end from here  */}
      {!sticky && (
        <div className="bg-gray-100 dark:bg-gray-950">
          <div className="container">
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-5 sm:col-span-5 lg:col-span-4">
                <ul className="flex justify-start items-center ">
                  <li className="px-1 py-1 hidden lg:block border-r dark:border-gray-800 lg:px-3 cursor-pointer">
                    <Link to={"#"} className="text-xs text-gray-600 dark:text-white">
                      Help line
                    </Link>
                  </li>
                  <li className="px-1 py-1 lg:px-3 cursor-pointer">
                    <Link to={"#"}  className="text-xs text-gray-600 dark:text-white flex items-center">
                      <FiPhoneCall className="mr-2 flex lg:hidden" />
                      {phone || "+880-195444-3121"}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-span-7 sm:col-span-7 lg:col-span-8">
                <ul className="flex justify-end">
                  {customerToken && customerToken.length > 20 ? (
                    <li className="px-1 py-1 border-r dark:border-gray-800 lg:px-3 cursor-pointer">
                      <Link
                        className="text-xs text-gray-600 dark:text-white"
                        to={"/profile"}
                      >
                        My Panel
                      </Link>
                    </li>
                  ) : (
                    <li className="px-1 py-1 border-r lg:px-3 cursor-pointer">
                      <Link
                        className="text-xs text-gray-600 dark:text-white"
                        to={"/register"}
                      >
                        Registration
                      </Link>
                    </li>
                  )}
                  <li className="pl-1 py-1 lg:px-3">
                    {customerToken && customerToken.length > 20 ? (
                      <button
                          onClick={()=>handleLogout(customerToken,setCustomerToken,navigate())}
                        className="text-xs text-gray-600 dark:text-white"
                      >
                        Logout
                      </button>
                    ) : (
                      <Link
                        to={"/login"}
                        className="text-xs text-gray-600 dark:text-white"
                      >
                        Login
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-transparent dark:bg-gray-900">
        {showSideBar && (
          <div
            className="nav-menu-layout"
            onClick={() => setShowSideBar(false)}
          ></div>
        )}
        <div className={"container"}>
          <div className={"grid grid-cols-12 gap-4 items-center"}>
            <div
              className={
                "col-span-6 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2"
              }
            >
              <div className={"logo-container"}>
                <div className="grid grid-cols-12 items-center">
                  <button className="col-span-2 p-0">
                    <HiBars3CenterLeft
                      onClick={() => setShowSideBar(true)}
                      size={25}
                      className="sm:block md:hidden lg:hidden mr-2 text-secondary dark:text-white"
                    />
                  </button>
                  <div className="col-span-10 p-0 lg:col-span-12">
                    <div
                      onClick={() => {
                        navigate("/");
                      }}
                      className="h-16"
                    >
                      {dark_logo || logo ? (
                        <img
                          className={" h-full w-auto"}
                          src={
                            darkMode
                              ? `${baseUrl.img}${dark_logo}`
                              : `${baseUrl.img}${logo}`
                          }
                          alt={"website logo"}
                        />
                      ) : (
                        <img
                          className={"w-16 h-16 lg:w-20 object-cover"}
                          src="https://placehold.co/80x80"
                          alt={"website logo"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                "col-span-0 md:col-span-7 lg:col-span-5 xl:col-span-5 2xl:col-span-6"
              }
            >
              <div
                className={`nav-menu-container bg-transparent  ${
                  showSideBar ? "left0 bg-white dark:bg-slate-800" : ""
                }`}
              >
                <button className="sm:block md:hidden lg:hidden text-end">
                  <RxCross1
                    onClick={() => setShowSideBar(false)}
                    size={20}
                    className="text-secondary dark:text-red-400"
                  />
                </button>
                <ul
                  className={
                    "m-0 p-0 sm:block md:flex lg:flex items-center roboto"
                  }
                >
                  {navItems?.map((item, i) => (
                    <li key={i} className="mr-5">
                      <button
                        className={`border-none flex items-center ${
                          item.route === location
                            ? "active-nav-menu "
                            : "bg-transparent"
                        }`}
                        onClick={() => {
                          navigate(item.route);
                          setViewAllFlashSell(0);
                          setDiscount(0);
                          setFeatureCat(0);
                          setSubCategoryId(null);
                          setMinPrice(null);
                          setRefreshAll(!refreshAll);
                          setMaxPrice(null);
                          setCategoryId("");
                          setProductName("");
                          setShowSideBar(false);
                        }}
                      >
                        {item?.icon}{" "}
                        <span className="ml-2 dark:text-white whitespace-nowrap">
                          {item.page}
                        </span>
                      </button>
                    </li>
                  ))}
                  {staticMenus?.map((item, i) => {
                    if (item.static_menu_type.name === "Footer") {
                      return null;
                    }
                    return (
                      <li key={i} className="mr-5">
                        <button
                          className={`border-none flex items-center ${
                            item.route === location
                              ? "active-nav-menu "
                              : "bg-transparent"
                          }`}
                          onClick={() => {
                            navigate(`/page/${item.id}`);
                            setViewAllFlashSell(0);
                            setDiscount(0);
                            setFeatureCat(0);
                            setSubCategoryId(null);
                            setMinPrice(null);
                            setRefreshAll(!refreshAll);
                            setMaxPrice(null);
                            setCategoryId("");
                            setProductName("");
                            setShowSideBar(false);
                          }}
                        >
                          {item?.icon}{" "}
                          <span className="ml-2 dark:text-white whitespace-nowrap">
                            {item.menu_name}
                          </span>
                        </button>
                      </li>
                    );
                  })}
                  <button
                    className={
                      "border-none bg-transparent flex items-center ml-2 "
                    }
                    onClick={() => {
                      setSearchbar(true);
                      setShowSideBar(false);
                    }}
                  >
                    <TfiSearch size={20} className="dark:text-white " />
                  </button>
                </ul>
              </div>
            </div>

            <div
              className={
                "ml-auto col-span-5 md:col-span-3 lg:col-span-5 xl:col-span-5 2xl:col-span-4"
              }
            >
              <div className="grid grid-cols-12 items-center gap-3 sm:gap-3 lg:gap-4">
                {customerToken && customerToken?.length > 20 ? (
                  <div className="col-span-4">
                    <div className="rounded-circle border-0 flex items-center justify-end">
                      <Link to="/wishlist" className="relative">
                        <button
                          className={
                            "border-0 bg-transparent flex items-center"
                          }
                        >
                          <IoMdHeartEmpty
                            className="dark:text-white"
                            size={25}
                          />
                          {wishlist?.length > 0 && (
                            <div className="absolute -top-2  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                              {wishlist?.length}
                            </div>
                          )}
                          <div className="hidden sm:hidden lg:block text-start ml-2">
                            <span className="text-sm dark:text-white">
                              Wishlist
                            </span>
                            <h5 className="text-xs -mt-1 dark:text-white">
                              Products
                            </h5>
                          </div>
                        </button>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-4"></div>
                )}
                <div className="col-span-4">
                  <div className="rounded-circle border-0 flex items-center justify-end">
                    <Link to="/compare" className="relative">
                      <button
                        className={"border-0 bg-transparent flex items-center"}
                      >
                        <VscGitCompare className="dark:text-white" size={20} />
                        {compare?.length > 0 && (
                          <div className="absolute -top-2  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                            {compare?.length}
                          </div>
                        )}
                        <div className="hidden sm:hidden lg:block text-start ml-2">
                          <span className="text-sm dark:text-white">
                            Compare
                          </span>
                          <h5 className="text-xs -mt-1 dark:text-white">
                            Products
                          </h5>
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-span-4">
                  <div className="relative">
                    <div className="rounded-full cart flex items-center justify-end">
                      <Link
                        className="relative flex items-center text-decoration-none"
                        to={"/cart"}
                      >
                        <BsCart3 className="dark:text-white" size={21} />
                        {cartItems?.length > 0 && (
                          <div className="absolute -top-2  left-3 bg-red-400 w-4 h-4 text-center text-xs rounded-full text-white">
                            {cartItems?.length}
                          </div>
                        )}
                        <div className="hidden sm:hidden lg:block ml-2 text-start">
                          <span className="text-sm dark:text-white">Cart</span>
                          <h5 className="text-xs -mt-1 dark:text-white">
                            Cart items
                          </h5>
                        </div>
                      </Link>
                      <div className="cart-hover-item bg-white dark:bg-slate-800">
                        {cartItems?.length > 0 ? (
                          <div>
                            {cartItems?.slice(0, 5)?.map((cart, i) => (
                              <div key={i} className="grid grid-cols-12 mb-2">
                                <div className="col-span-3">
                                  <img
                                    className="dark:bg-slate-900 rounded-lg"
                                    style={{ height: "50px", width: "50px" }}
                                    src={`${baseUrl.img}${cart?.product_combination?.product?.thumbnail_image}`}
                                    alt="cart"
                                  />
                                </div>
                                <div className="col-span-9">
                                  <p
                                    style={{ lineHeight: "15px" }}
                                    className="p-0 m-0 fw-bold font-medium dark:text-white"
                                  >
                                    {cart?.product_combination?.product?.name}
                                  </p>
                                  <p className="dark:text-white">
                                    {cart?.product_quantity} X ৳
                                    {flash &&
                                    cart?.product_combination?.product
                                      ?.is_on_sale === 1 ? (
                                      <span>
                                        {(
                                          cart?.product_combination
                                            ?.selling_price -
                                          (cart?.product_combination
                                            ?.selling_price *
                                            flash.discount) /
                                            100
                                        ).toFixed(2)}
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          cart?.product_combination
                                            ?.selling_price
                                        }
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="p-3 text-center">
                            <div style={{ width: "60px" }} className="mx-auto">
                              <img
                                src={require("../../../assets/cart/no-item.png")}
                                alt=""
                                className="w-full"
                              />
                            </div>
                            <p className="dark:text-white">No item in cart</p>
                          </div>
                        )}
                        <div>
                          <Link className="block" to={"/cart"}>
                            <Button
                              classes={"w-full"}
                              size={"md"}
                              title={"View Cart"}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
