import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import Contact from "../Pages/Contact/Contact";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import Cart from "../Pages/Cart/Cart";
import WishlistPage from "../Pages/WishlistPage/WishlistPage";
import AllProducts from "../Pages/AllProducts/AllProducts";
import CheckoutPage from "../Pages/CheckoutPage/CheckoutPage";
import ComparePage from "../Pages/ComparePage/ComparePage";
import OrderTab from "../Components/ProfileMenu/OrderTab/OrderTab";
import Login from "../Pages/Login/Login";
import Register from "../Pages/Register/Register";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import OrderDetails from "../Components/ProfileMenu/OrderDetails/OrderDetails";
import CategoryPage from "../Pages/CategoryPage/CategoryPage";
import UpdateInfo from "../Components/ProfileMenu/AccountTab/UpdateInfo/UpdateInfo";
import ProfileMenu from "../Components/ProfileMenu/ProfileMenu";
import Faq from "../Pages/Faq/Faq";
import Pages from "../Layout/Pages/Pages";
import NoData from "../Pages/NoData/NoData";
import AccountInfo from "../Components/ProfileMenu/AccountTab/AccountInfo/AccountInfo";
import OrderHistory from "../Components/ProfileMenu/OrderHistory/OrderHistory";
import ShippingDetails from "../Components/ProfileMenu/ShippingDetails/ShippingDetails";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: (
      <NoData
        status={404}
        err={"No page found"}
        errDetails={"It may seems, no data available for this route."}
      />
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },

      {
        path: "/details/:id/:slug",
        element: <ProductDetails />,
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <ProfileMenu />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "/profile",
            element: <AccountInfo />,
          },
          {
            path: "/profile/shipping",
            element: <ShippingDetails />,
          },
          {
            path: "/profile/update",
            element: <UpdateInfo/>,
          },
          {
            path: "/profile/order",
            element: <OrderTab />,
          },

          {
            path: "/profile/order/details/:id",
            element: <OrderDetails />,
          },
          {
            path: "/profile/history",
            element: <OrderHistory />,
          },
        ],
      },
      {
        path: "/compare",
        element: <ComparePage />,
      },

      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/wishlist",
        element: <WishlistPage />,
      },
      {
        path: "/products",
        element: <AllProducts />,
      },
      {
        path: "/product-by-category",
        element: <CategoryPage />,
      },
      {
        path: "/checkout",
        element: (
          <ProtectedRoute>
            <CheckoutPage />
          </ProtectedRoute>
        ),
      },

      {
        path: "/page/:id",
        element: <Pages />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },

      {
        path: "/faq",
        element: <Faq />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
]);
