import React from "react";
import "./Footer2.css";
import {GoLocation} from "react-icons/go";
import {TfiEmail} from "react-icons/tfi";
import {BsTelephone, BsInstagram, BsPinterest} from "react-icons/bs";
import {FaFacebookF} from "react-icons/fa";
import {GrLinkedinOption} from "react-icons/gr";
import {FiTwitter} from "react-icons/fi";
// import {MdSupportAgent } from "react-icons/md";
import {AiOutlineYoutube} from "react-icons/ai";
import {Link, useNavigate} from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import {footerMenu} from "../../../Hooks/Helper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Footer2 = ({staticMenus}) => {
    const {setting} = useStore().data;

    const navigate = useNavigate();
    const {
        facebook,
        instagram,
        linkedin,
        twitter,
        youtube,
        pinterest,
        phone,
        address,
        email,
        name,
    } = setting;
    const newTabWithLink = (link) => {
        window.open(link);
    };

    return (
        <div className="bg-white dark:bg-gray-900">
            <SectionWrapper last={true}>
                <div>
                    <div className="text-center mb-5">
                        <h5 className="text-2xl font-semibold text-slate-700 dark:text-white">
                            Join the Ecommerce Adventure
                        </h5>
                        <p className="text-sm text-slate-500 w-full lg:w-4/12 mx-auto dark:text-white">
                            Follow Us for Exclusive Offers and a Peek into Our Thriving Online Community on Facebook,
                            Instagram,
                            Twitter, and LinkedIn
                        </p>
                        <div className="flex items-center justify-center mt-2">
                            <div className="flex">
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(facebook)}
                                    className=""
                                >
                                    <FaFacebookF size={14} className="dark:text-white hover:text-red-400"/>
                                </button>
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(instagram)}
                                    className=""
                                >
                                    <BsInstagram size={14} className=" dark:text-white hover:text-red-400"/>
                                </button>
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(twitter)}
                                    className=""
                                >
                                    <FiTwitter size={14} className=" dark:text-white hover:text-red-400"/>
                                </button>
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(youtube)}
                                    className=""
                                >
                                    <AiOutlineYoutube size={14} className=" dark:text-white hover:text-red-400"/>
                                </button>
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(linkedin)}
                                    className=""
                                >
                                    <GrLinkedinOption size={14} className=" dark:text-white hover:text-red-400"/>
                                </button>
                                <button
                                    style={{height: "35px", width: "35px"}}
                                    onClick={() => newTabWithLink(pinterest)}
                                    className=""
                                >
                                    <BsPinterest size={14} className=" dark:text-white hover:text-red-400"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-4 mt-8">
                        <div className="">
                            {
                                staticMenus?.length > 0 &&
                                <h5 className="text-lg font-medium text-slate-700 dark:text-white">
                                    Important Links
                                </h5>
                            }
                            <ul className="">
                                {staticMenus?.map((item, i) => {
                                    if (item.static_menu_type.name === "Header") {
                                        return null;
                                    }
                                    return (
                                        <li key={i} className="">
                                            <button
                                                className={`border-0`}
                                                onClick={() => {
                                                    navigate(`/page/${item.id}`);
                                                }}
                                            >
                      <span className=" text-slate-600 text-sm dark:text-white">
                        {item.menu_name}
                      </span>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {footerMenu?.map((menu, i) => (
                            <div key={i} className="">
                                <div>
                                    <h5 className="text-lg font-medium text-slate-700 dark:text-white">
                                        {menu.heading}
                                    </h5>
                                    <ul>
                                        {menu.links.map((item, i) => (
                                            <li key={i}>
                                                <Link
                                                    className="text-sm text-slate-600 dark:text-white"
                                                    to={item.route}
                                                >
                                                    {item.linkName}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div className="">
                            <div>
                                <h5 className="text-lg font-medium text-slate-700 dark:text-white">
                                    Contact us
                                </h5>
                                <div className="mb-1 flex items-center">
                <span>
                  {" "}
                    <GoLocation className=" dark:text-white text-red-400"/>
                </span>
                                    <span className="text-sm text-slate-600 dark:text-white ml-2">
                  {address}
                </span>
                                </div>
                                <div className="mb-1 flex items-center">
                <span>
                  {" "}
                    <TfiEmail className=" dark:text-white text-red-400"/>
                </span>
                                    <span className="text-sm text-slate-600 dark:text-white ml-2">
                  {email}
                </span>
                                </div>
                                <div className="flex items-center">
                <span>
                  {" "}
                    <BsTelephone className=" dark:text-white text-red-400"/>
                </span>
                                    <span className="text-sm text-slate-600 dark:text-white ml-2">
                  {phone}
                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center mt-8 lg:mt-16">
                    <div className="container my-4">
                        <div className="w-12/12 lg:w-10/12 mx-auto">
                            <img
                                className="w-full"
                                src={require("../../../assets/payment/sslcommerz-banner.webp")}
                                alt=""
                            />
                        </div>
                    </div>
                    <p className="text-sm pb-2 text-slate-500">
                        Copyright 2023 © <span className="">{name}</span> All
                        rights reserved. Powered by{" "}
                        <span className="text-red-400">Selopia</span>.{" "}
                    </p>
                </div>
            </SectionWrapper>

        </div>
    );
};

export default Footer2;
